import React from "react";
import { Toast } from "react-bootstrap";

interface Props {
  show: boolean;
  onClose: () => void;
  header: string;
  body: string;
}

const SwToast = (props: Props) => {
  const { show, onClose, header, body } = props;

  return (
    <Toast onClose={onClose} show={show} delay={3000} autohide>
      <Toast.Header>
        <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
        <strong className="me-auto">{header}</strong>
        <small>{new Date().toLocaleTimeString()}</small>
      </Toast.Header>
      <Toast.Body>{body}</Toast.Body>
    </Toast>
  );
};

export default SwToast;
