import React from "react";
import { FormattedMessage } from "gatsby-plugin-intl";

import Layout from "../../components/layout";
import Breadcrumbs from "../../components/breadcrumbs";
import Step1 from "../../components/NewUserWizard/steps/step-1";

const NewUserPage: React.FC<{ location: any }> = ({ location }) => {
  return (
    <Layout>
      <Breadcrumbs
        parentPageUrl="/user-management"
        parentPageTitle={
          <FormattedMessage
            id="user-management"
            defaultMessage="User Management"
          />
        }
        currentPageTitle={
          <FormattedMessage id="new_user" defaultMessage="New User" />
        }
      ></Breadcrumbs>
      <h1 className="block-title">
        <FormattedMessage id="new_user" defaultMessage="New User" />
      </h1>
      <Step1 />
    </Layout>
  );
};

export default NewUserPage;
