import { Link, navigate, useIntl } from "gatsby-plugin-intl";
import React, { useState } from "react";
import InlineFormError from "../../form/InlineFormError";
import { useForm } from "react-hook-form";
import { NewUser } from "../../../types/form/new-user-data.type";
import useCreateNewUserStore from "./useNewUserStore";
import {
  doesUserExist,
  getUserGroups,
} from "../../../services/userManagementService";
import SwToast from "../../toast/sw-toast";

const Step1 = () => {
  const intl = useIntl();
  const [showFailToast, setShowFailToast] = useState<boolean>(false);
  const [showErrorToast, setShowErrorToast] = useState<boolean>(false);

  const {
    setFirstName,
    setLastName,
    setEmail,
    setCompanyName,
    setJobTitle,
    setDepartment,
    reset,
  } = useCreateNewUserStore();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Pick<NewUser, "email">>({
    // validate on submit - button must be enabled for this to work
    mode: "onSubmit",
    // after submit, revalidate form when user completes a field
    reValidateMode: "onBlur",
    defaultValues: {
      email: "",
    },
  });

  const onSubmit = (data: Pick<NewUser, "email">) => {
    reset();

    setEmail(data.email);

    //Check if user exists on tennant
    doesUserExist(data.email)
      .then((response) => {
        const { userExists, userDetails, userId } = response.data;
        if (!userExists) {
          navigate("/user-management/new-user-details/");
        } else if (userDetails && userId) {
          const { givenName, surname, jobTitle, companyName, department } =
            userDetails;

          // If user exists on tennant, check if already a PPP member
          getUserGroups(userId)
            .then((response) => {
              const isPPPMember = !!response.data.find(
                (group) => group.id === process.env.GATSBY_PPP_USERS_GROUP_ID
              );

              if (isPPPMember) {
                setShowFailToast(true);
              } else {
                setFirstName(givenName);
                setLastName(surname);
                setJobTitle(jobTitle);
                setCompanyName(companyName);
                setDepartment(department);
                navigate("/user-management/new-user-details/");
              }
            })
            .catch((error) => {
              console.error(error);
              setShowErrorToast(true);
            });
          // If user has a pending invitation 
        } else if (userDetails && userDetails.magicLink) {
          setShowFailToast(true);
        }
      })
      .catch((error) => {
        console.error(error);
        setShowErrorToast(true);
      });
  };
  return (
    <section className="row">
      <div className="col-12 col-lg-6">
        <h2>Step 1 of 3</h2>

        <form
          onSubmit={handleSubmit(onSubmit)}
          autoComplete="off"
          className="mb-4"
        >
          <fieldset className="border rounded p-3 mb-3">
            <legend>Email address</legend>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Email (required)
              </label>
              <input
                type="email"
                className="form-control"
                {...register("email", { required: true })}
              />
              {errors.email?.type === "required" && (
                <InlineFormError message="Email is required" />
              )}
            </div>
          </fieldset>
          <button type="submit" className="btn btn-primary">
            Next
          </button>
        </form>
        <div className="wizard-nav__exits mb-4">
          <Link
            className="btn btn-sm btn-outline-secondary me-3"
            to={"/user-management"}
          >
            Cancel
          </Link>
        </div>
      </div>
      <SwToast
        show={showFailToast}
        onClose={() => setShowFailToast(false)}
        header={intl.formatMessage({
          id: "invite-user.fail",
          defaultMessage: "User already exists",
        })}
        body={intl.formatMessage({
          id: "invite-user.fail.message",
          defaultMessage:
            "A user with that email address already exists in PPP",
        })}
      ></SwToast>
      <SwToast
        show={showErrorToast}
        onClose={() => setShowErrorToast(false)}
        header={intl.formatMessage({
          id: "error",
        })}
        body={intl.formatMessage({
          id: "error",
        })}
      ></SwToast>
    </section>
  );
};

export default Step1;
