import { User, Group } from "@microsoft/microsoft-graph-types-beta";
import { handleErrors } from "./getRequest";
import { Subset } from "../types/Subset.type";

export async function getAllPPPUsers(): Promise<User[]> {
  return fetch("/api/getAllPPPUsers")
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    })
    .catch((error) => {
      console.error(
        "There has been a problem with the fetch operation:",
        error
      );
    });
}

export async function doesUserExist(email: string) {
  const body = { email: email };

  const response = await fetch(`/api/doesUserExist`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(body),
  });
  
  return await response.json();
}

export async function editUser(user: User) {
  const body = { user };

  const response = await fetch(`/api/editUser`, {
    method: "PATCH",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(body),
  });

  const statusCode = response.status;
  
  return await response.json().then(data=> ({statusCode, data}));
}

export async function getUserDetail(userId: string | undefined) {
  return await fetch(`/api/getUserDetail/${userId}`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  }).then((response) => {
    return response.json();
  });
}

export async function getAllGroups() {
  return fetch("/api/getAllGroups").then((response) => {
    return response.json();
  });
}

export function getGroupDetail(groupId: string) {
  return fetch(`/api/getGroupDetail/${groupId}`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  }).then((response) => {
    return response.json();
  });
}

export function getGroupMembers(groupId: string): Promise<{ data: User[] }> {
  if (!groupId) {
    throw new Error("No group id");
  }

  return fetch(`/api/getGroupMembers/${groupId}`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  }).then((response) => {
    return response.json();
  });
}

export function getUserGroups(userId: string): Promise<{ data: Group[] }> {
  return fetch(`/api/getUserGroups/${userId}`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  }).then((response) => {
    return response.json();
  });
}

export async function addGroupMember(userId: string, groupId: string) {
  const body = { userid: userId, groupid: groupId };

  const response = await fetch(`/api/addGroupMember`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(body),
  });
  return await response;
}

export async function removeGroupMember(userId: string, groupId: string) {
  const body = { userid: userId, groupid: groupId };

  const response = await fetch(`/api/removeGroupMember`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(body),
  });
  return await response;
}

export async function inviteUser({
  firstName,
  lastName,
  email,
  roles,
  sports,
  companyName,
  jobTitle,
  department,
  expiryDate,
  userExists,
  userId,
  displayName
}: {
  firstName: string;
  lastName: string;
  email: string;
  roles: string[];
  sports: (Subset<{ value: any; label: string | undefined; }> | undefined)[]
  companyName: string | undefined;
  jobTitle: string | undefined;
  department: string | undefined;
  expiryDate: string;
  userExists: boolean;
  userId: string | undefined;
  displayName: string | undefined
}) {
  const formattedSports = sports.map((sport) => {
    return {
      permissionId: sport?.value,
      displayName: sport?.label
    }
  })
  const body = {
    firstName,
    lastName,
    email,
    roles,
    sports: formattedSports,
    companyName,
    jobTitle,
    department,
    expiryDate,
    userExists, 
    userId,
    displayName
  };

  const response = await fetch(`/api/user`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(body),
  });
  return await response.json();
}

export async function getInvitation(invitationId: string) {
  return await fetch(`/api/invitation/${invitationId}`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  }).then((response) => {
    return response.json();
  });
}

export function getUserInvitations(userId: string) {
  return fetch(`/api/user/invitation/${userId}`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  }).then((response) => {
    return response.json();
  });
}

export async function acceptInvitation({
  invitationId,
  acceptedDate,
  password,
  userExistsInTennant,
}: {
  invitationId: string;
  acceptedDate: string;
  password: string | null;
  userExistsInTennant: boolean;
}) {
  const response = await fetch(`/api/invitation/accept/${invitationId}`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ acceptedDate, password, userExistsInTennant }),
  }).then(handleErrors);
}

export async function regenerateInvitation(invitationId: string) {
  const response = await fetch(`/api/invitation/${invitationId}`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
  }).then(handleErrors);
  return await response.json();
}
